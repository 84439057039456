import React from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';
import { ErrorBoundary } from 'react-error-boundary';

import Layout from '../components/Layout';
import PrivateRoute from '../components/PrivateRoute/PrivateRoute';
import PreviewComponent from '../components/PreviewComponent/PreviewComponent';

const ErrorFallback = ({ error }) => (
  <Layout>
    <div className="grid">
      <div className="g-col-12">
        <br />
        <p>Something went wrong:</p>
        <br />
        <p>{error.message}</p>
      </div>
    </div>
  </Layout>
);

const Preview = () => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Router>
      <PrivateRoute path="/preview" component={PreviewComponent} />
    </Router>
  </ErrorBoundary>
);

ErrorFallback.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

ErrorFallback.defaultProps = {
  error: {},
};

export default Preview;
