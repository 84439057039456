import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useErrorHandler } from 'react-error-boundary';
import { useLazyQuery } from '@apollo/client';

import SitePage from '../../templates/sitePage';
import Layout from '../Layout';
import { getPreviewContentQuery } from '../../apollo/query/preview';
import { getPreviewHeaderFooterContentQuery } from '../../apollo/query/previewHeaderFooter';
import { transformData } from '../../utils/transformData';

const PreviewComponent = ({ tokenParam, slugParam }) => {
  const [previewData, setPreview] = useState({});
  const [getPreviewData, { error, data }] = useLazyQuery(getPreviewContentQuery, {
    context: { headers: { Authorization: `Bearer ${tokenParam}` } },
    variables: { slug: slugParam },
  });
  const [getPreviewDataHeaderFooter, { error: errorHeaderFooter, data: dataHeaderFooter }] = useLazyQuery(
    getPreviewHeaderFooterContentQuery,
    {
      context: { headers: { Authorization: `Bearer ${tokenParam}` } },
      variables: { slug: slugParam },
    },
  );
  const handleError = useErrorHandler();

  useEffect(() => {
    Promise.resolve().then(getPreviewData).then(getPreviewDataHeaderFooter);
  }, [tokenParam, slugParam]);

  useEffect(() => {
    const formPreview = () => {
      const currentObj = data && !previewData?.firstDataLoaded ? data : dataHeaderFooter;

      if (!currentObj) return;
      const { pageCollection: { items = [] } = {} } = currentObj;

      if (!items.length) {
        return;
      }
      const { content: { header, footer, contentCollection: { items: blocks = [] } = {} } = {} } = items[0];

      const newContent =
        data && !previewData?.firstDataLoaded
          ? {
              ...previewData?.contentfulComposePage?.content,
              content: transformData(blocks),
            }
          : {
              ...previewData?.contentfulComposePage?.content,
              header: transformData(header, true),
              footer: transformData(footer, true),
            };

      setPreview({
        ...previewData,
        firstDataLoaded: true,
        previewMode: true,
        contentfulComposePage: {
          content: {
            ...newContent,
          },
        },
      });
    };

    try {
      formPreview();
    } catch (e) {
      handleError(e);
    }
  }, [data, dataHeaderFooter]);

  if (error || errorHeaderFooter) return handleError(error || errorHeaderFooter);

  /*eslint-disable*/
  return <>{previewData.previewMode ? <SitePage data={previewData} /> : <Layout />}</>;
};

PreviewComponent.propTypes = {
  tokenParam: PropTypes.string,
  slugParam: PropTypes.string,
};

PreviewComponent.defaultProps = {
  tokenParam: '',
  slugParam: '',
};

export default PreviewComponent;
