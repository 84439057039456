import { gql } from '@apollo/client';

export const getPreviewHeaderFooterContentQuery = gql`
  query ($slug: String!) {
    pageCollection(where: { slug: $slug }, preview: true, limit: 1) {
      items {
        name
        title
        slug
        content {
          header {
            logo {
              title
              url
            }
            logoMobileTablet {
              title
              url
            }
            menu {
              menuItems: menuItemsCollection(limit: 7, preview: true) {
                items {
                  title
                  link {
                    ... on ExternalNavigation {
                      slug
                    }
                    ... on Page {
                      slug
                    }
                  }
                  subMenu {
                    menuItems: menuItemsCollection(limit: 7, preview: true) {
                      items {
                        title
                        link {
                          ... on ExternalNavigation {
                            slug
                          }
                          ... on Page {
                            slug
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          footer {
            keepInTouch {
              title
              subtitle
            }
            description
            menu {
              menuItems: menuItemsCollection(limit: 10, preview: true) {
                items {
                  title
                  link {
                    slug
                  }
                }
              }
            }
            socials {
              socialLinks: socialLinksCollection(limit: 10, preview: true) {
                items {
                  title
                  link
                  icon {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
