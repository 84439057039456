import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { useQueryParam, StringParam } from 'use-query-params';

const isBrowser = typeof window !== 'undefined';

const PrivateRoute = ({ component: Component }) => {
  if (!isBrowser) {
    return null;
  }

  const [tokenParam] = useQueryParam('token', StringParam);
  const [slugParam] = useQueryParam('slug', StringParam);

  if (!tokenParam || !slugParam) {
    navigate('/');
    return null;
  }

  return <Component slugParam={slugParam} tokenParam={tokenParam} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.func,
};

PrivateRoute.defaultProps = {
  component: undefined,
};

export default PrivateRoute;
