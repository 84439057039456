import { gql } from '@apollo/client';

export const getPreviewContentQuery = gql`
  query ($slug: String!) {
    pageCollection(where: { slug: $slug }, preview: true, limit: 1) {
      items {
        name
        title
        slug
        content {
          name
          contentCollection(limit: 30, preview: true) {
            items {
              title
              __typename
              component: componentCollection(limit: 5, preview: true) {
                items {
                  __typename
                  ... on HeroBanner {
                    title
                    titleSize
                    subtitle
                    image {
                      url
                    }
                  }

                  ... on Image {
                    title
                    image {
                      url
                    }
                  }

                  ... on Catalog {
                    tiles: tilesCollection(limit: 15, preview: true) {
                      items {
                        title
                        subtitle {
                          raw: json
                        }
                        image {
                          url
                        }
                        link {
                          slug
                        }
                      }
                    }
                  }

                  ... on Text {
                    title
                    richtext {
                      raw: json
                    }
                  }

                  ... on Collout {
                    isColorBackground
                    isImageLeft
                    title
                    isFloatImage
                    richtext {
                      raw: json
                    }
                    image {
                      url
                    }
                    url
                  }

                  ... on VisitCardsList {
                    title
                    cards: cardsCollection(limit: 15, preview: true) {
                      items {
                        title
                        subtitle
                        iconBackground
                        icon {
                          url
                        }
                        email
                        telephone
                      }
                    }
                  }

                  ... on SmallImagesCallout {
                    isColorBackground
                    title
                    description
                    isHorizontal
                    imageList: imageListCollection(limit: 15, preview: true) {
                      items {
                        title
                        image {
                          url
                        }
                        showTitle
                        objectFit
                        link
                      }
                    }
                  }

                  ... on OneLineTiles {
                    tiles: tilesCollection(limit: 15, preview: true) {
                      items {
                        title
                        subtitle {
                          raw: json
                        }
                        link {
                          slug
                        }
                        image {
                          url
                        }
                      }
                    }
                  }

                  ... on ContactUs {
                    phone
                    title
                    email
                    description
                  }

                  ... on BenefitsList {
                    title
                    subtitleBenefit: subtitle
                    benefitItems: benefitItemsCollection(limit: 10, preview: true) {
                      items {
                        title
                        icon {
                          url
                        }
                        text
                        color
                      }
                    }
                  }

                  ... on OneLineTilesWithDescription {
                    title
                    desc: description
                    tiles: tilesCollection(limit: 10, preview: true) {
                      items {
                        title
                        link {
                          slug
                        }
                        subtitle {
                          raw: json
                        }
                        image {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
