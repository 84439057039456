import cloneDeep from 'clone-deep';

export const reformatObject = (item, options) => ({ ...item, ...options });

export const reformatID = (item) => reformatObject(item, { id: item.sys?.id });

export const reformatImage = (item) => reformatObject(item, { file: { url: item.url } });

export const reformatItemsCollection = (item) => reformatObject(item, { component: item.component?.items });

export const iterateObject = (obj) => {
  let newObj = obj;
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i += 1) {
    switch (keys[i]) {
      case 'component':
        newObj = reformatItemsCollection(newObj);
        break;
      case 'sys':
        newObj = reformatID(newObj);
        break;
      case 'image':
      case 'icon':
      case 'logo':
        if (newObj[keys[i]] !== null) {
          newObj[keys[i]] = reformatImage(newObj[keys[i]]);
        }
        break;
      case 'subtitle':
      case 'description':
        if (!newObj[`is${keys[i]}`]) {
          // eslint-disable-next-line no-underscore-dangle
          switch (newObj.__typename) {
            case 'ContentfulHeroBanner':
            case 'ContentfulContactUs':
            case 'ContentfulFooter':
            case 'ContentfulSmallImagesCallout':
              newObj[keys[i]] = { [keys[i]]: newObj[keys[i]], [`is${keys[i]}`]: true };
              break;
            default:
              break;
          }
        }
        break;
      case 'raw':
        newObj[keys[i]] = JSON.stringify(newObj[keys[i]]);
        break;
      // eslint-disable-next-line no-underscore-dangle
      case '__typename':
        if (!newObj[keys[i]].includes('Contentful')) {
          newObj[keys[i]] = `Contentful${newObj[keys[i]]}`;
        }
        break;
      default:
        if (newObj[keys[i]] !== null && newObj[keys[i]].items) {
          newObj[keys[i]] = newObj[keys[i]].items;
          delete newObj[keys[i]].items;
        }
        break;
    }

    if (typeof newObj[keys[i]] === 'object' && newObj[keys[i]] !== null) {
      newObj = {
        ...newObj,
        // eslint-disable-next-line no-use-before-define
        [keys[i]]: newObj[keys[i]] instanceof Array ? iterateArray(newObj[keys[i]]) : iterateObject(newObj[keys[i]]),
      };
    }
  }
  return newObj;
};

export const iterateArray = (arr) => arr.map((item) => {
  if (typeof item !== 'object') {
    return item;
  }
  if (item instanceof Array) {
    return iterateArray(item);
  }
  return iterateObject(item);
});

export const transformData = (data, isAloneObject) => {
  if(!data) return;

  if (!data.length && !isAloneObject) {
    return [];
  }

  const cloneData = cloneDeep(isAloneObject ? [data] : data);
  return isAloneObject ? iterateArray(cloneData)[0] : iterateArray(cloneData);
};
